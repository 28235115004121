<template>
  <el-container style="height: 100%; padding: 0 20px 20px;">
    <el-header class="g-bg g-mb-15" style="display: flex; align-items: center; justify-content: space-between;">
      <week-select-view @change="onTimeChange"></week-select-view>
      <el-button type="primary" style="margin-left: auto;" @click="onExport">导出</el-button>
      <el-button type="primary" @click="$router.push('/report/list/index')">周报上报 </el-button>
    </el-header>
    <el-container style="overflow: hidden;">
      <el-aside class="g-bg g-mr-15">
        <el-scrollbar y>
          <dept-tree @change="onTreeChange" :current-key="1"></dept-tree>
        </el-scrollbar>
      </el-aside>
      <el-main class="g-bg" v-loading="loading">
        <el-scrollbar y view-style="padding: 15px;">
          <div v-if="list.length === 0" class="g-empty"></div>
          <div class="list-box" :style="{ height: boxHeight + 'px' }">
            <div
              ref="item"
              class="list-item"
              :style="{ top: item._top + 'px', left: item._left }"
              :data-index="index"
              v-for="(item, index) in list"
              :key="item.id"
            >
              <div class="list-item__inner">
                <div class="name">{{ item.name }}</div>
                <div class="item" v-for="it in item.schedules" :key="it.id">
                  <span class="label">{{ it.schedule_date.split('-').slice(1).join('/') }}</span>
                  <div class="value">
                    {{ it.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <b-pagination :page="page" @change="fetchList"></b-pagination>
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import WeekSelectView from '@/components/select/week'
import DeptTree from '@/components/tree/dept'
import { getReportPage, exportReport } from '@/api/report'

export default {
  components: {
    DeptTree,
    WeekSelectView,
  },
  data() {
    return {
      loading: false,
      currentDept: 1,
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      list: [],
      timeData: {},
      boxHeight: 100,
    }
  },
  methods: {
    fetchList() {
      this.loading = true
      const params = {
        page: this.page.current,
        department_id: this.currentDept,
        start_date: this.timeData.start_date,
        end_date: this.timeData.end_date,
      }
      getReportPage(params)
        .then(res => {
          const data = res.data.filter(item => item.schedules.length > 0)
          data.forEach(item => {
            item._left = 0
            item._top = 0
          })
          this.list = data
          this.page.total = res.total

          this.$nextTick(() => {
            const items = this.$refs.item
            // console.log(items)
            if (items) {
              const arr = [0, 0]
              items.forEach(item => {
                const index = arr[0] > arr[1] ? 1 : 0
                const listIndex = +item.dataset.index
                this.list[listIndex]._top = arr[index]
                // console.log(this.list[listIndex], listIndex, index, item.offsetHeight)

                if (index === 1) {
                  this.list[listIndex]._left = '50%'
                }
                arr[index] = arr[index] + item.offsetHeight
              })
              this.boxHeight = arr[0] > arr[1] ? arr[0] : arr[1]
            }
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    onExport() {
      const params = {
        start_date: this.timeData.start_date,
        end_date: this.timeData.end_date,
      }
      exportReport(params).then(res => {
        window.open(res.url)
      })
    },
    onTreeChange(id) {
      this.currentDept = id
      this.fetchList()
    },
    onTimeChange(time) {
      this.timeData = time
      this.fetchList()
    },
  },
}
</script>
<style lang="scss" scoped>
.list-box {
  position: relative;
}

.list-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  padding: 10px;

  .list-item__inner {
    padding: 20px;
    background: rgba($color: #dfe3e8, $alpha: 0.26);
    border-radius: 8px;
  }

  .name {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #2b2d42;
  }

  .item {
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    overflow: hidden;
    font-size: 14px;
    line-height: 24px;
    color: #747e8f;

    .label {
      width: 50px;
    }

    .value {
      flex: 1;
      min-width: 0;
      color: #2b2d42;
    }
  }
}
</style>
